<template>
  <select class="select" v-model="proj">
    <option v-for="p in projects" :value="p" :key="p">{{ p }}</option>
  </select>
</template>

<script>
import { mapState } from 'vuex'

export default {
  mounted() {
    if (this.projects.length == 1)
      this.proj = this.projects[0]
  },
  computed: {
    ...mapState(['url', 'project', 'projects']),
    proj: {
      get() {
        return this.project
      },
      set(v) {
        let v1 = this.projects.find(el => el == v)
        console.log(v)
        console.log(v1)
        this.$store.commit('setProject', v1)
      }
    }
  },
  watch: {
    project(v, old) {
      if (v != old) {
        this.$store.dispatch('updateCompanies')
        if (v) {
          this.$store.dispatch('getData')
        }
      }
    }
  }
}
</script>

<style lang="sass">
.select
  min-width: 200px
  font-size: 18px
  padding: 2px 5px
  border-radius: 5px
</style>