<template>
  <div class="filter">
    <div class="filter__title">Выберите фильтр</div>
    <div class="filter__select-container">
      <select class="select" v-model="filtername">
        <option value=""></option>
        <option v-for="f in filters" :value="f.name" :key="f.name">{{ f.name }}</option>
      </select>
      <Btn :small="true" @click="createFilter">+</Btn>
    </div>
    <div class="filter__title">Список страниц</div>
    <textarea :class="{ 'filter__input_green': urlsInSync }" class="filter__input filter__textarea" v-model="urls"></textarea>
    <div class="filter__buttons">
      <Btn :small="true" @click="save">Сохранить</Btn>
      <Btn :small="true" @click="apply">Применить</Btn>
    </div>
  </div>
</template>


<script>
import { mapGetters, mapState } from 'vuex'
import Btn from './buttons/Btn.vue'

export default {
  data: () => ({
    filters: [],
    urls: '',
  }),
  components: { Btn },
  methods: {
    save() {
      this.updateFilter()
    },
    apply() {
      this.$store.dispatch('getData', { payload: this.clearUrls })
    },
    async getFilters() {
      this.$store.commit('toggleLoad', 'filter')
      try {
        const res = await fetch(this.url + 'filters' + `?project=${encodeURIComponent(this.project)}`, {
          credentials: 'include',
        })
        if (!res.ok) throw await res.text()
        this.filters = await res.json()
      } catch (err) {
        alert(err)
        console.error(err)
      }
      this.$store.commit('toggleLoad', 'filter')
    },
    async createFilter() {
      const name = prompt('Введите имя фильтра')
      if (!name) return
      this.$store.commit('toggleLoad', 'filter')
      try {
        const res = await fetch(this.url + 'filter', {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ project: this.project, name, urls: [] })
        })
        if (!res.ok) throw await res.text()
        await this.getFilters()
      } catch (err) {
        alert(err)
        console.error(err)
      }
      this.$store.commit('toggleLoad', 'filter')
    },
    async updateFilter() {
      if (!this.filtername)
        return console.error('no filter')
      const name = this.filtername, urls = this.clearUrls
      this.$store.commit('toggleLoad', 'filter')
      try {
        const res = await fetch(this.url + 'filter', {
          method: 'PUT',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ project: this.project, name, urls })
        })
        if (!res.ok) throw await res.text()
        await this.getFilters()
      } catch (err) {
        alert(err)
        console.error(err)
      }
      this.$store.commit('toggleLoad', 'filter')
    },
    async deleteFilter() {
      const name = prompt('Введите имя фильтра')
      if (!name) return
      this.$store.commit('toggleLoad', 'filter')
      try {
        const res = await fetch(this.url + 'filter', {
          method: 'DELETE',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ project: this.project, name })
        })
        if (!res.ok) throw await res.text()
        await this.getFilters()
      } catch (err) {
        alert(err)
        console.error(err)
      }
      this.$store.commit('toggleLoad', 'filter')
    },
  },
  async mounted(){
    if (this.filtername) {
      console.log('FILTERNAME!!!', this.filtername)
      const saved = this.filtername
      this.filtername = ''
      await this.getFilters()
      const found = this.filters.find(el => el.name === saved)
      if (found) {
        this.filtername = saved
        console.log('dispatching', found.urls)
        this.$store.dispatch('getData', { payload: found.urls })
      } 
    } else {
      await this.getFilters()
    }
  },
  computed: {
    filtername: {
      get(){
        return this.$store.state.filtername
      },
      set(v) {
        this.$store.commit('setFiltername', v)
      }
    },
    ...mapState(['project', 'url']),
    ...mapGetters(['isfilter']),
    currentFilter(){
      return this.filters.find(el => el.name === this.filtername)
    },
    clearUrls() {
      return this.urls.split('\n').map(el => el.trim()).filter(el => el)
    },
    urlsInSync() {
      if (!this.filtername || !this.currentFilter) return false
      if (this.clearUrls.length !== this.currentFilter.urls?.length)
        return false
      for (let i = 0; i < this.clearUrls.length; i++) {
        if (this.clearUrls[i] !== this.currentFilter.urls[i])
          return false
      }
      return true
    }
  },
  watch: {
    filters(v) {
      const found = v.find(el => el.name === this.filtername)
      if (!found) {
        this.filtername = ''
      }
    },
    filtername(v, old) {
      if (!v) return this.urls = ''
      if (v !== old) {
        const found = this.filters.find(el => el.name === v)
        if (found) {
          this.urls = found.urls.join('\n')
        } else {
          this.filtername = ''
          this.urls = ''
        }
      }
    },
    project(v, old) {
      if (v !== old) {
        this.getFilters()
      }
    }
  }
}
</script>


<style lang="sass" scoped>
.filter
  &__title
    font-weight: 700
    margin-bottom: 5px
  &__buttons
    display: flex
    justify-content: space-between
    >*
      flex-basis: calc(50% - 10px)

  &__select-container
    display: flex
    align-items: center
    gap: 10px
    margin-bottom: 10px
    .select
      min-width: auto
      flex-grow: 1
  &__textarea
    white-space: pre
    padding: 5px !important
    font-size: 11px
    letter-spacing: -0.08em
    resize: horizontal
  &__input
    transition: .3s ease-out
    outline: none
    padding: 5px 14px
    width: 100%
    height: 400px
    font-weight: 500
    border: 1px solid rgba(0,0,0,.1)
    border-radius: 7px
    margin-bottom: 5px
    &:focus-visible
      border-color: rgba(0,0,0,.3)
    &_green
      background: rgba(230, 255, 230, 1)
</style>
