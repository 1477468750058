<template>
  <Teleport to="body">
    <Transition name="fade">
      <div class="addpopup" @click="$emit('update:modelValue', false)" v-show="modelValue">
        <div class="addpopup__content" @click.stop>
          <div style="margin-bottom: 5px">Выберите группу:</div>
          <form ref="updateForm" @submit.prevent="updateGroup">
            <select v-model="selectedGroup" class="select" @change="onGroupChange">
              <option v-for="group in groupnames" :key="group" :value="group">{{ group }}</option>
            </select>
            <div class="addpopup__checks">
              <label v-for="company in companies_without_all" :key="company">
                <input ref="checkboxes" type="checkbox" :value="company" v-model="selectedCompanies" name="company_names[]">
                <div>{{ company }}</div>
              </label>
            </div>
            <div class="addpopup__message" v-if="tempMessage">{{ tempMessage }}</div>
            <Btn type="submit" :disabled="!selectedGroup">Изменить</Btn>
          </form>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Btn from './buttons/Btn.vue'

export default {
  props: {
    modelValue: { type: Boolean, default: false }
  },
  components: { Btn },
  data() {
    return {
      selectedGroup: '',
      selectedCompanies: [],
      tempMessage: '',
      timer: null
    }
  },
  computed: {
    ...mapState(['project','url', 'groups']),
    ...mapGetters(['groupnames', 'companies_without_all'])
  },
  methods: {
    onGroupChange() {
      const group = this.groups.find(g => g.name === this.selectedGroup);
      this.selectedCompanies = group ? group.company_names : [];
    },
    async updateGroup() {
      const formData = new FormData(this.$refs.updateForm);
      const data = {
        project: this.project,
        group: this.selectedGroup,
        company_names: formData.getAll('company_names[]')
      };

      try {
        const res = await fetch(this.url + 'group', {
          method: 'PUT',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        });

        if (!res.ok) {
          throw await res.text();
        }


        clearTimeout(this.timer)
        this.tempMessage = 'Успешно'
        this.timer = setTimeout(()=>{
          this.tempMessage = ''
        },1000)        
      } catch (err) {
        console.error(err);
        alert(err);
      }
    }
  },
  watch: {
    selectedGroup(v) {
      let enabled = []
      if (v) {
        const found = this.groups.find(el => el.name == v)
        enabled = found?.company_names || []
      }
      console.log(enabled)
      for (let checkbox of this.$refs.checkboxes) {
        checkbox.checked = enabled.includes(checkbox.value)
      }
    },
    modelValue(v) {
      if (!v) {
        this.$store.dispatch('updateCompanies')
        this.$store.dispatch('getData')
      }
    }
  }
}
</script>

<style lang="sass">
.addpopup
  position: fixed
  z-index: 11
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  width: 100%
  height: 100%
  top: 0
  left: 0
  background: rgba(255,255,255,.6)
  &__message
    margin-bottom: 10px
    font-weight: 700
  &__content
    width: 550px
    max-width: 100%
    background: rgb(230, 230, 255)
    padding: 15px 20px
    border-radius: 20px
    box-shadow: 1px 1px 4px rgba(0,0,0,.3)
    overflow-y: auto
    >*:not(:last-child)
      margin-bottom: 15px
  &__input
    width: 100%
    padding: 5px 10px
    background: white
    border: 1px solid rgba(0,0,0,.15)
  &__checks
    max-height: 400px
    overflow-y: auto 
    margin: 20px 0
    label
      display: flex
      align-items: center
      font-size: 16px
      &:not:last-child
        margin-bottom: 8px
      input
        margin-right: 5px
</style>
