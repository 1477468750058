<template>
  <div class="pdata">
    <div class="pdata__info">
      <div class="pdata__info-selectors">
        <div class="pdata__btn" v-if="Role == 2">
          <Btn class="pdata__btn" @click="$refs.file.click()">Загрузить файлы</Btn>
          <input class="pdata__file" ref="file" type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" multiple name="excel" @change="sendFiles">
        </div>
        <Filter v-if="isfilter"/>
      </div>
      <div class="pdata__info-data">
        <Table v-if="project && (company || isfilter) && table" />
      </div>
    </div>
  </div>
</template>

<script>
import Btn from './buttons/Btn.vue';
import { mapState, mapGetters } from 'vuex'
import Table from './Table.vue';
import Filter from './Filter.vue';
export default {
  computed: {
    ...mapState(["project", "companies", "company", "Role", 'table']),
    ...mapGetters(["isfilter"])
  },
  components: { Btn, Table, Filter },
  methods: {
    async sendFiles() {
      let a = new FormData()
      for (let file of this.$refs.file.files)
        a.append('files[]', file)
      a.append('project', this.project)
      try {
        await this.$store.dispatch('sendFiles', a)
      } catch (err) {
        alert(err)
      }
      await this.$store.dispatch('updateCompanies', a)
      this.$refs.file.value = ''
    },
  },
}
</script>

<style lang="sass" scoped>
.pdata
  padding-top: 30px
  display: flex
  flex-direction: column
  &__getdata
    width: 100% !important
    min-width: 100% !important
    max-width: 100% !important
    flex-shrink: 0 !important
  .filler
    flex-grow: 1
  &__label
    display: block
    &_exc
      >*:not(:last-child)
        margin-bottom: 10px
      .pdata__input:not(:last-child)
        margin-bottom: 5px
  &__input
    padding: 5px 8px
    border-radius: 4px
    border: 1px solid rgba(0,0,0,.4)
    outline: none
    display: block
    width: 100%
    &:not([type=text])
      cursor: pointer
    &:focus
      border-color: rgba(0,0,0,.8)
  &__info
    z-index: 5
    display: flex
    flex-grow: 1
    align-items: stretch
    &-data
      flex-grow: 1
      display: flex
      flex-direction: column
    &-selectors
      width: 200px
      flex-shrink: 0
      position: sticky
      top: 10px
      min-height: calc(100vh - 80px)
      align-self: flex-start
      display: flex
      flex-direction: column
      margin-right: 15px
      >*
        margin-bottom: 10px
  &__doublebtns
    display: flex
    justify-content: space-between
    position: relative
    &-dash
      width: auto !important
      position: absolute
      left: 50%
      transform: translateX(-50%)
      top: 0
      font-size: 16px
      line-height: 30px
    >*
      width: calc(50% - 12.5px)
  &__file
    position: absolute
    opacity: 0
    pointer-events: none
  &__btn
    width: 100%
    align-self: stretch
    font-size: 18px
    &-exc
      width: 100%
    &_spaced
      margin-top: 20px
</style>