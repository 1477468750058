<template>
  <div class="tablezone">
    <div class="uptable-controls tablezone__controls">
      <Btn class="tablesave btn btn_blue" @click="save">Сохранить таблицу</Btn>
      <Btn v-if="Role == 2" class="tablesave btn btn_blue" @click="getLink">Получить ссылку</Btn>
    </div>
    <!--
    <table class="table" ref="table" >
    -->
    <div style="width: 100%; padding-top: 30px; margin-top: -30px;">
      <table class="table" ref="table" :data-cols-width="getColWidth">
        <thead>
          <tr>
            <td class="datecell" data-a-h="center" data-a-v="middle" @click.exact="setSortIndex(0)">
              <b>Школа</b>
              <span class="sortarrow" :class="{ 'sortarrow_reverse': sort.direction == -1 }" v-if="sortEnabled && (sort.idx == 0)">↓</span>
            </td>
            <td class="datecell" data-a-h="center" data-a-v="middle" @click.exact="setSortIndex(1)">
              <b>URL</b>
              <span class="sortarrow" :class="{ 'sortarrow_reverse': sort.direction == -1 }" v-if="sortEnabled && (sort.idx == 1)">↓</span>
            </td>
            <td class="datecell" data-a-h="center" data-a-v="middle" @click.exact="setSortIndex(2)">
              <b>Название</b>
              <span class="sortarrow" :class="{ 'sortarrow_reverse': sort.direction == -1 }" v-if="sortEnabled && (sort.idx == 2)">↓</span>
            </td>
            <td v-for="(d, index) in filteredDates" :key="d" class="datecell" :class="{ 'selectedDate': selectedDates.has(d) }" data-a-h="center" data-a-v="middle" @click.exact="setSortIndex(dateIndex[index] + 3)" @click.alt.exact="selectDate(d)">
              <b>{{ shortdates[index] }}</b>
              <button class="datecell__delete" v-if="Role == 2" @click="$store.dispatch('deleteDate', d)">&times;</button>
              <span class="sortarrow" :class="{ 'sortarrow_reverse': sort.direction == -1 }" v-if="sortEnabled && (sort.idx == dateIndex[index] + 3)">↓</span>
            </td>
            <td v-if="selectedDates.size > 1" class="datecell" data-a-h="center" data-a-v="middle">
              <b>Разница</b>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="i in tableIndex" :key="i">
            <td class="link" data-a-h="center" data-a-v="middle">
              <div>{{ keys[i].comp }}</div>
            </td>
            <td class="link" data-a-h="center" data-a-v="middle">
              <div>{{ keys[i].url }}</div>
            </td>
            <td class="link" data-a-h="center" data-a-v="middle">
              <div>{{ table[i][0] }}</div>
            </td>
            <td data-a-h="center" data-a-v="middle" v-for="d, j in filteredDates" :key="j + '-' + d">
              {{ table[i][dateIndex[j] + 1] }}
            </td>
            <td v-if="selectedDates.size > 1" class="datecell" data-a-h="center" data-a-v="middle">
              <b>{{ diff[i] }}</b>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Btn from './buttons/Btn.vue';
import TableToExcel from "@linways/table-to-excel"

export default {
  data: () => ({
    sortdate: false,
    sortreverse: false,
    sort: {
      idx: null,
      direction: 1
    },
    selectedDates: new Set()
  }),
  components: {
    Btn
  },
  mounted() {
    this.$nextTick(() => {
      if (this.logdata.dload) {
        let dload = this.logdata.dload
        this.$store.commit('setlogdata', {})
        dload = dload.filter(el => this.dates.includes(el))
        this.selectedDates = new Set(dload)
      }
    })
  },
  computed: {
    ...mapState(['Role', 'table', 'dates', 'company', 'logdata','filtername', 'isgroup']),
    ...mapGetters(['dindex', 'keys','isfilter']),
    diff() {
      let res = {}
      if (this.selectedDates.size < 2)
        return res
      for (let key of this.tableIndex) {
        let v = this.table[key][this.dateIndex[1] + 1] - this.table[key][this.dateIndex[0] + 1]
        if (v > 0)
          v = '+' + v
        res[key] = v
      }
      return res
    },
    sortEnabled() {
      return this.sort.idx !== null
    },
    tableIndex() {
      let res = Object.keys(this.table)
      if (this.selectedDates.size > 1) {
        let idx = this.dateIndex
        let res2 = []
        for (let key of res) {
          let f = this.table[key][1 + idx[0]]
          if (isNaN(parseInt(f)))
            continue
          for (let i = 1; i < idx.length; i++)
            if ((f != this.table[key][1 + idx[i]]) && (!isNaN(parseInt(this.table[key][1 + idx[i]])))) {
              res2.push(key)
              break
            }
        }
        res = res2
      }
      if (this.sort.idx === null)
        return res
      const idx = this.sort.idx - 2
      switch (this.sort.idx) {
        case 0:
          res.sort((a, b) => this.keys[a].comp.localeCompare(this.keys[b].comp) * this.sort.direction)
          break;
        case 1:
          res.sort((a, b) => this.keys[a].url.localeCompare(this.keys[b].url) * this.sort.direction)
          break;
        case 2:
          res.sort((a, b) => this.table[a][0].localeCompare(this.table[b][0]) * this.sort.direction)
          break;
        default:
          res.sort((a, b) => (this.table[a][idx] - this.table[b][idx]) * this.sort.direction)
      }
      return res
    },
    shortdates() {
      return this.filteredDates.map(el => el.split('-').slice(0, 2).join('.'))
    },
    filteredDates() {
      if (this.selectedDates.size < 2)
        return this.dates
      return this.dates.filter(el => this.selectedDates.has(el))
    },
    dateIndex() {
      if (this.selectedDates.size < 2)
        return this.dates.map((el, i) => i)

      const indexArray = []
      let a = this.dates
      let b = this.filteredDates
      let i = 0
      let j = 0

      while (i < a.length && j < b.length) {
        if (a[i] === b[j]) {
          indexArray.push(i)
          j++
        }
        i++
      }

      return indexArray

    },
    getColWidth() {
      //let base = "40"
      //let res = base + this.worksheets.map(() => ",15").join("")
      return "40,40,40,40"
    },
  },
  methods: {
    save() {
      this.$nextTick(() => {
        try {
          TableToExcel.convert(this.$refs.table, {
            name: this.company + ".xlsx",
            sheet: {
              name: "Данные"
            }
          })
        } catch (err) {
          console.error(err)
        }
      })
    },
    getLink() {
      if (this.isfilter && !this.filtername) {
        return alert('Можно получить ссылку только на сохранённый фильтр')
      }
      this.$store.dispatch('getLink', { dateset: this.selectedDates, company: this.company, isfilter: this.isfilter, filtername: this.filtername, isgroup: this.isgroup })
    },
    selectDate(date) {
      if (this.selectedDates.has(date)) {
        this.selectedDates.delete(date)
      }
      else {
        this.selectedDates.add(date)
      }
    },
    setSortIndex(idx) {
      if (idx === this.sort.idx) {
        this.sort.direction *= -1
      } else {
        this.sort.idx = idx
        this.sort.direction = 1
      }
    }
  },
}
</script>

<style lang="sass" scoped>
$danger: #dc3545
$success: #198754
$primary: #0d6efd

.tablesave
  font-size: 18px  
.sortarrow
  line-height: 0
  color: darken(colors.$blue,20)
  font-weight: 900
  margin-left: 4px
  font-size: 20px
  display: inline-block
  transform: scaleY(.9)
  &_reverse
    transform: scale(1,-0.9) translateY(-3px)
.uptable-controls
  display: flex
  gap: 20px

.tablezone
  &__controls
    display: flex
    gap: 10px
    margin-bottom: 20px
.table  
  display: block
  max-width: 100%
  font-size: 14px
  margin-bottom: 30px
  .selectedDate
    border: 2px solid $primary !important
  thead
    position: sticky
    top: 0
    z-index: 4
    &::after
      content: ''
      background: white 
      position: absolute
      top: 0
      left: 0
      right: 0
      bottom: 0
      top: 0
      display: block
      z-index: -1
    td
      background: white
  th, td
    border: 2px solid rgba(darken($primary, 20%), .2)
  td:nth-child(1)
    width: 175px
    min-width: 175px
    max-width: 175px
  td:nth-child(2)
    width: 225px
    min-width: 225px
    max-width: 225px
  td:nth-child(3)
    width: 225px
    min-width: 225px
    max-width: 225px
  tr
    font-weight: 500
    white-space: nowrap
  td.bad
    background: rgba(255,200,200) !important
  td.good
    background: rgb(200,255,200) !important
  td.active
    background: white !important
    position: relative
    padding-right: 15px
    &::after
      position: absolute
      content: '↑'
      right: 2px
      font-size: 18px
      top: 50%
      transform: translateY(-65%)
      transform-origin: center center
  td.filtered
    outline: 2px solid violet
    .datecell__count
      opacity: 1
      pointer-events: auto
  .inversed .active::after
    content: '↓'

  td
    padding: 3px 10px
    position: relative

    &:nth-child(1), &:nth-child(2), &:nth-child(3)
      background: #ebffff !important
    .tooltip
      pointer-events: none !important
      background: rgba(black, .9)
      color: white
      padding: 5px 10px
      border-radius: 5px
      top: calc(100% + 5px)
      left: 50%
      transform: translateX(-50%)
      position: absolute
      opacity: 0
      transition: .2s ease-out
      width: max-content
      span
        display: block
        max-width: 300px
    &:hover .tooltip
      opacity: 1
    &:nth-child(n+4)
      text-align: center
    &.group
      text-align: left
      width: 150px !important
      max-width: 150px !important
      min-width: 150px !important
      position: relative !important
    background: white
    &.link

      z-index: 2
      max-width: 300px
      overflow: hidden
      position: sticky
      left: 0
      background: white !important
      &:nth-child(1), &:nth-child(2), &:nth-child(3)
        font-weight: 600

      &:hover 
        overflow: visible
        padding: 0
        z-index: 3
        div
          position: relative
          width: fit-content
          padding: 3px 10px
          z-index: 1
          background: rgba(255,255,255,.8)
.datecell
  position: relative
  &__delete
    width: 20px
    background: rgba(255,255,255,.05)
    border: none
    position: absolute
    pointer-events: none
    opacity: 0
    bottom: 100%
    line-height: 0
    right: -11px
    z-index: 100
    background: rgba(0,0,0,.5)
    width: 17px
    height: 17px
    border-radius: 9999px
    text-align: center
    display: flex
    justify-content: center
    align-items: center
    font-size: 16px
    font-weight: 700
    color: #4fffff
    padding: 3px
    pointer-events: none
    transition: opacity .3s ease-out
    cursor: pointer
    opacity: 0
  &:hover &__input, &:focus-within &__input
    pointer-events: auto
    opacity: 1
  &__count
    transition: opacity .2s ease-out
    opacity: 0
    pointer-events: none
    text-align: center
    width: auto
    min-width: 30px
    padding: 2px 8px
    position: absolute
    left: 50%
    transform: translateX(-50%)
    bottom: calc(100% + 5px)
    border: none
    background: white
    border: 2px solid violet
  &__input
    transition: opacity .2s ease-out
    opacity: 0
    pointer-events: none
    width: 100px
    position: absolute
    right: 100%
    top: 0
    bottom: 0
    border: none
    background: white
    border: 2px solid black
    &_broken
      border: 2px solid red !important
  &:hover &__delete 
    opacity: 1
    pointer-events: auto
  &:hover &__delete:hover
    background: black
</style>