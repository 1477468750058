<template>
  <select class="select" :class="{ 'select_groupselected': groupselected,  'select_allselected': allselected, 'select_filter': isfilter }" v-model="proj">
    <option value=""></option>
    <option class="select__groupall" v-if="is_all_exists" :value="JSON.stringify({ company: 'all', isgroup: 0 })">all</option>
    <option class="select__filter" :value="JSON.stringify({ company: '', isgroup: 2 })">Фильтр</option>
    <option class="select__group" v-for="g in groupnames" :value="JSON.stringify({ company: g, isgroup: 1 })" :key="g">{{ g }}</option>
    <option v-for="p in companies_without_all" :value="JSON.stringify({ company: p, isgroup: 0 })" :key="p">{{ p }}</option>
  </select>
</template>

<script>
import { mapState } from 'vuex'
import { mapGetters } from 'vuex/dist/vuex.cjs.js'

export default {
  computed: {
    ...mapState(['url', 'company', 'companies', 'isgroup']),
    ...mapGetters(['groupnames', 'companies_without_all', 'is_all_exists','isfilter']),
    groupselected() {
      if (!this.proj)
        return false
      return (this.groupnames.includes(JSON.parse(this.proj).company))
    },
    allselected(){
      if (!this.proj)
        return false
      return this.is_all_exists && (JSON.parse(this.proj).company == 'all')
    },
    proj: {
      get() {
        let comp = this.company 
        console.log(this.isgroup)
        console.log(comp)
        if (this.isgroup == 2)
          comp = ''
        console.log(JSON.stringify({ company: comp, isgroup: this.isgroup }))
        return JSON.stringify({ company: comp, isgroup: this.isgroup })
      },
      set(v) {
        if (!v)
          return
        const json = JSON.parse(v)
        if (json.isgroup == 2) {
          this.$store.commit('setIsGroup', json.isgroup)
          return this.$store.commit('setCompany', '')
        }
        const found = [...this.companies, ...this.groupnames].includes(json.company)
        if (!found)
          return console.error('companySelector: не найдено')
        this.$store.commit('setIsGroup', json.isgroup)
        this.$store.commit('setCompany', json.company)
      }
    }
  },
  watch: {
    company(v, old) {
      if (v != old) {
        if (v)
          this.$store.dispatch('getData')
      }
    },
    isfilter(v,old) {
      if (!old && v) {
        this.$store.commit('setTable', null)
      }
    }
  }
}
</script>

<style lang="sass">
.select
  background: white
  border: 1px solid rgba(0,0,0,.2)
  min-width: 200px
  font-size: 18px
  padding: 2px 5px
  border-radius: 5px
  &_groupselected
    font-weight: 700
    color: colors.$group
  &_filter, & &__filter
    font-weight: 700
    color: colors.$filter
  &_allselected
    font-weight: 700
  & &__group
    font-weight: 700
    color: colors.$group
  &__groupall
    font-weight: 700
  option
    color: black
</style>