<template>
  <Transition name="fade">
    <div class="loginscreen">
      <video id="background-video" autoplay loop muted>
        <source :src="require('@/assets/waves2.webm')" type="video/webm" />
      </video>
      <form class="form" @submit.prevent.stop>
        <input type="text" v-model="log" placeholder="Логин">
        <input type="password" v-model="pass" placeholder="Пароль">
        <button @click.prevent.stop="login">Войти </button>
        <div class="form__error" v-if="error">Неверный логин или пароль</div>
      </form>
    </div>
  </Transition>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'LoginScreen',
  data: () => ({
    log: '',
    pass: '',
    error: false
  }),
  mounted() {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    const loglink = params.loglink
    const cload = params.cload
    const dload = params.dload
    const fload = params.fload
    console.log('here c/d/f:')
    console.log(cload)
    console.log(dload)
    console.log(fload)
    if (loglink) {
      this.$router.push('/login')
      this.$store.dispatch('login', { loglink, cload, dload, fload })
    }
  },
  methods: {
    async login() {
      this.error = await this.$store.dispatch('login', { log: this.log, pass: this.pass })
    }
  },
  computed: {
    ...mapState(['url'])
  }
}
</script>

<style lang="sass" scoped>
.loginscreen
  background: white
  background-size: cover 
  position: fixed
  width: 100%
  height: 100%
  left:  0
  top: 0
  z-index: 100
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  overflow: hidden
  video
    position: absolute
    z-index: -1
    min-width: 100%
    min-height: 100%
    aspect-ratio: 16 / 9
    opacity: .2
    filter: blur(5px)
@keyframes appear
  0%
    transform: translateY(50px)
  100%
    transform: translateY(0px)
.form
  width: 350px
  padding: 25px
  border-radius: 25px
  background: linear-gradient(123deg, lighten(colors.$blue, 35) 0%, darken(colors.$green, 15) 70%, darken(colors.$green, 35) 100%)
  font-size: 16px
  animation: appear .5s ease-in
  &__error
    font-size: 12px
    margin-top: 12px
    margin-left: auto
    text-align: right
    color: red
    font-weight: 700
    text-shadow: -1px -1px 0 #fee, 1px -1px 0 #fee, -1px 1px 0 #fee, 1px 1px 0 #fee

  input
    width: 100%
    padding: 8px 15px
    border-radius: 7px
    margin-bottom: 15px
    background: white
    border: none
    &:not(::focus-visible)
      outline: none
  button
    font-weight: 500
    margin-left: auto
    padding: 5px 15px
    background: white
    border: none
    border-radius: 7px
    cursor: pointer
    margin-left: auto
    display: block
    &:hover
      background: lighten(#30a189,50)
      
    &:active
      background: darken(colors.$green, 20)
    &:not(::focus-visible)
      outline: none
</style>