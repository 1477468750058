import { createStore } from 'vuex'
import router from '@/router/index.js'

export default createStore({
  state: {
    url: '',
    loads: {},
    logdata: {},
    project: null,
    projects: [],
    company: null,
    filtername: null,
    isgroup: 0,
    groups: [],
    companies: [],
    dates: [],
    table: null,
    allusers: [],
    Role: null
  },
  mutations: {
    setlogdata(state, v) {
      state.logdata = v
    },
    setFiltername(state, v) {
      state.filtername = v
    },
    setDates(state, dates) {
      state.dates = dates
    },
    setAllUsers(state, users) {
      state.allusers = users
    },
    setTable(state, table) {
      state.table = table
    },
    setUrl(state, url) {
      state.url = url
    },
    toggleLoad(state, name) {
      if (state.loads[name])
        delete state.loads[name]
      else
        state.loads[name] = true
    },
    setRole(state, Role) {
      state.Role = Role
    },
    setProject(state, value) {
      state.project = value
    },
    setProjects(state, value) {
      state.projects = value
    },
    setCompany(state, value) {
      state.company = value
    },
    setIsGroup(state, value) {
      state.isgroup = value
    },
    setCompanies(state, value) {
      state.companies = value
    },
    setGroups(state, value) {
      state.groups = value
    },
  },
  actions: {
    reset({ commit }) {
      commit('setRole', false)
    },
    async login({ state, commit, dispatch }, { log, pass, loglink, cload, dload, fload }) {
      this.error = false
      if (loglink) {
        log = pass = undefined
      }
      commit('toggleLoad', 'Logscreen')
      let res = await fetch(state.url + 'auth', {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ log, pass, loglink })
      }).then(async res => {
        if (!res.ok) throw (await res.text())
        return res.text()
      }).then(async role => {
        if (role) {
          if (loglink) {
            const cl = JSON.parse(decodeURIComponent(cload))
            commit('setlogdata', { cload: cl?.company, isgroup: cl?.isgroup, dload: JSON.parse(decodeURIComponent(dload)), fload: JSON.parse(decodeURIComponent(fload)) })
            console.log(JSON.stringify(state.logdata))
          }
          commit('setRole', role)
          await dispatch('updateProjects')
          router.push('/app')
        }
        else
          return 'error'
      }).catch(err => { console.error(err); return 'error' })
      commit('toggleLoad', 'Logscreen')
      return res
    },
    async checkAuth({ state, commit }) {
      commit('toggleLoad', 'checkAuth')
      await fetch(state.url + 'auth', {
        credentials: 'include',
      })
        .then(res => {
          if (!res.ok) return commit('setRole', false)
          return res.text()
        }).then(role => {
          commit('setRole', role || false)
        }).catch(err => {
          console.error(err)
          commit('setRole', false)
        })
      commit('toggleLoad', 'checkAuth')
    },
    async updateAllUsers({ state, commit }) {
      commit('toggleLoad', 'updateAllUsers')
      await fetch(state.url + 'users', {
        credentials: 'include',
      })
        .then(async res => {
          if (!res.ok) throw (await res.text())
          return res.json()
        })
        .then(list => {
          commit('setAllUsers', list)
        })
        .catch(err => { alert(err); console.error(err) })
      commit('toggleLoad', 'updateAllUsers')
    },
    async updateProjects({ state, commit }) {
      commit('toggleLoad', 'updateProjects')
      await fetch(state.url + 'projects', {
        credentials: 'include',
      })
        .then(async res => {
          if (!res.ok) throw (await res.text())
          return res.json()
        })
        .then(list => {
          commit('setProjects', list)
          if (state.project) {
            let found = list.includes(state.project)
            if (!found)
              commit('setProject', null)
          }
        })
        .catch(err => { alert(err); console.error(err) })
      commit('toggleLoad', 'updateProjects')
    },
    async updateCompanies({ state, commit, getters }) {
      if (!state.project) {
        commit('setCompany', null)
        commit('setIsGroup', 0)
        commit('setCompanies', [])
        commit('setGroups', [])
        return
      }

      commit('toggleLoad', 'updateCompanies')
      await fetch(state.url + 'companies' + `?project=${encodeURIComponent(state.project)}`, {
        credentials: 'include',
      })
        .then(async res => {
          if (!res.ok) throw (await res.text())
          return res.json()
        })
        .then(({ list, groups }) => {
          commit('setCompanies', list)
          commit('setGroups', groups)
          if (state.isgroup == 2)
            return

          let toSearch = (state.isgroup == 1) ? getters.groupnames : list
          if (state.company) {
            let found = toSearch.includes(state.company)
            if (!found) {
              commit('setCompany', null)
              commit('setIsGroup', 2)
              commit('setFilterName', )
            }
          }

          let v = state.logdata.fload
          if (v) {
            commit('setCompany', null)
            commit('setFiltername', v)
            commit('setIsGroup', 2)
            commit('setlogdata', { dload: state.logdata.dload })
            return 
          }
          
          v = state.logdata.cload
          let isgroup = 0 
          if (state.logdata.isgroup === 1) {
            commit('setIsGroup', 1)
            isgroup = 1
            toSearch = getters.groupnames
          }
          if (v) {
            let found = toSearch.includes(v)
            if (found) {
              commit('setCompany', v)
              commit('setIsGroup', isgroup)
              commit('setlogdata', { dload: state.logdata.dload })
            }
            else {
              commit('setlogdata', {})
            }
          }
        })
        .catch(err => { alert(err); console.error(err) })
      commit('toggleLoad', 'updateCompanies')
    },
    async sendFiles({ state, commit, dispatch }, data) {
      commit('toggleLoad', 'sendFiles')
      await fetch(state.url + 'data', {
        method: 'POST',
        credentials: 'include',
        body: data,
      })
        .then(async res => {
          if (!res.ok) throw (await res.text())
          await dispatch('getData')
        })
        .catch(err => { alert(err); console.error(err) })
      commit('toggleLoad', 'sendFiles')
    },
    async getLink({ state, commit }, { dateset, company, isfilter, filtername, isgroup }) {
      if (!state.project)
        return
      function copyToClipboard(text) {
        const textarea = document.createElement('textarea')
        textarea.value = text
        document.body.appendChild(textarea)
        textarea.select()
        document.execCommand('copy')
        document.body.removeChild(textarea)
      }
      commit('toggleLoad', 'getLink')
      await fetch(state.url + 'loglink', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ project: state.project })
      }).then(async res => {
        if (!res.ok) throw (await res.text())
        return res.json()
      }).then(payload => {
        if (!payload) {
          throw ('Ошибка: ссылка не получена')
        }
        let dload = '', cload = '', fload = ''
        if (dateset) {
          dload = '&dload=' + encodeURIComponent(JSON.stringify(Array.from(dateset)))
        }
        const realFiltername = isfilter && filtername
        console.log(realFiltername)
        if (company) {
          cload = '&cload=' + encodeURIComponent(JSON.stringify({company, isgroup}))
        }
        if (realFiltername) {
          fload = '&fload=' + encodeURIComponent(JSON.stringify(realFiltername))
        } else {
          console.log('no fload!')
          console.log(isfilter)
          console.log(filtername)
        }
        const link = window.location.origin + `?loglink=${encodeURIComponent(payload)}` + dload + cload + fload
        console.log(link)
        copyToClipboard(link)
        if (!realFiltername)
          alert('Скопировано!')
        else 
          alert(`Скопирована ссылка на сохранённый фильтр ${realFiltername}!`)
      }).catch(err => { alert(err); console.error(err) })
      commit('toggleLoad', 'getLink')
    },
    async getData({ state, commit, getters }, options = {}) {
      if (!state.project || (!state.company && !getters.isfilter))
        return;

      commit('toggleLoad', 'getData');

      const mode = (() => {
        switch (state.isgroup) {
          case 0:
            return 'company';
          case 1:
            return 'group';
          case 2:
            return 'urls';
          default:
            return 0;
        }
      })();

      await fetch(state.url + `getdata${mode == 'urls' ? 'Urls' : ''}`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ [mode]: (options.payload || state.company), project: state.project })
      })
        .then(async res => {
          if (!res.ok) throw (await res.text());
          return res.json();
        })
        .then(shots => {
          let urls = {}, dates = new Set();

          for (let shot of shots) {
            dates.add(shot.date);
          }

          dates = Array.from(dates);
          let dates2 = dates.map(el => {
            let [d, m, y] = el.split('-').map(Number);
            return d + m * 100 + y * 10000;
          });
          dates = dates.map((el, i) => [el, dates2[i]]);
          dates.sort((a, b) => a[1] - b[1]);
          dates = dates.map(el => el[0]);

          let dindex = {};
          for (let i in dates) {
            dindex[dates[i]] = parseInt(i);
          }

          for (let shot of shots) {
            for (let i = 0; i < shot.data.length; i++) {
              let v = shot.company + ' || ' + shot.data[i].url;
              if (!urls[v]) {
                urls[v] = new Array(dates.length + 1);
                urls[v][0] = shot.data[i].name;
              }
            }
          }

          for (let shot of shots) {
            let didx = dindex[shot.date] + 1;
            for (let i = 0; i < shot.data.length; i++) {
              urls[shot.company + ' || ' + shot.data[i].url][didx] = shot.data[i].price;
            }
          }

          commit('setDates', dates);
          commit('setTable', urls);
        })
        .catch(err => { alert(err); console.error(err); });

      commit('toggleLoad', 'getData');
    },
    async deleteDate({ state, commit, dispatch }, date) {
      if (!date)
        return alert('Дата не указана')
      if (!confirm("Удалить все данные за " + date + "?"))
        return
      commit('toggleLoad', 'deleteDate')
      await fetch(state.url + 'data', {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ date: date, project: state.project })
      }).then(async res => {
        if (!res.ok) throw (await res.text())
      }).then(async () => {
        await dispatch('updateCompanies')
        if (state.company)
          dispatch('getData')
      })
        .catch(err => { console.error(err); alert(err) })

      commit('toggleLoad', 'deleteDate')
    }
  },
  getters: {
    keys(state) {
      if (!state.table)
        return {}
      let res = {}
      for (let key in state.table) {
        let split = key.split(' || ')
        res[key] = { comp: split[0], url: split[1].replace(/^https?:\/\//, '') }
      }
      return res
    },
    dindex(state) {
      if (!state.dates)
        return {}
      let dindex
      for (let i in state.dates) {
        dindex[state.dates[i]] = parseInt(i)
      }
      return dindex
    },
    loading(state) {
      return Object.keys(state.loads).length > 0
    },
    companies_without_all(state) {
      return state.companies.filter(el => el != 'all')
    },
    is_all_exists(state, getters) {
      return getters.companies_without_all.length != state.companies.length
    },
    isfilter(state) {
      return state.isgroup == 2
    },
    groupnames(state) {
      return state.groups.map(el => el.name)
    }
  }
})
