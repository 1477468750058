<template>
  <div>
    <div class="panel">
      <ProjectSelector class="panel__gap" />
      <ProjectBtn v-if="(Role == 2)" mode="create" />
      <ProjectBtn v-if="(Role == 2)" mode="delete" />
      <CompanySelector v-if="project" class="panel__gap" />
      <CompanyBtn v-if="project && (Role == 2)" mode="create" :onlygroup="true" />
      <CompanyBtn v-if="project && company && (isgroup) && (Role == 2)" :onlygroup="true" mode="edit" />
      <CompanyBtn v-if="project && company && (company != 'all') && (Role == 2)" mode="delete" />
      <LogoutBtn />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import LogoutBtn from './buttons/logoutBtn.vue';
import ProjectSelector from './ProjectSelector.vue';
import ProjectBtn from './buttons/ProjectBtn.vue';
import CompanySelector from './CompanySelector.vue';
import CompanyBtn from './buttons/CompanyBtn.vue';
export default {
  computed: {
    ...mapState(["url", "Role", "project","company","isgroup"]),
  },
  components: { LogoutBtn, ProjectSelector, ProjectBtn, CompanySelector, CompanyBtn }
}
</script>

<style lang="sass">
.panel
  display: flex
  gap: 10px
  &__right
    margin-left: auto
  &__gap
    margin-left: 20px
  select
    margin-right: 5px
    max-width: 200px
  button
    font-weight: 700
    width: 40px
    height: 40px
    max-height: 40px
    padding: 0
    display: flex
    justify-content: center
    align-items: center
    line-height: 0
</style>